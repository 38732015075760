"use client";
import { Typography, Grid, Container, Box, Button, Modal } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import { FC, useEffect, useState } from "react";
import { useRouter } from "next/router";
import useAuthUser from "@/helpers/authHandler/useAuthUser";
import thumbnail from "../assets/promo_thumbnail.webp";
import { RegisterComponent } from "@/content/login/login";
import { FRIEND } from "@/constants/category_types";
import { getUsersWithPhoto } from "@/HttpCalls/Friends/getUserInformation";
import Image from "next/image";
import { FIND_FRIENDS, PROFILE } from "@/constants/routes";
import myImageLoader from "@/image/loader";

const classes = {
    title: {
        fontSize: { xs: "24px", sm: "35px", md: "40px", lg: "50px" },
        fontWeight: 600,
    },
    button: {
        // width: { xs: "250px", md: "400px" },
        width: "90%",
        height: "auto",
    },
};

interface MasterclassHeaderProps {}

const JoinCommunity: FC<MasterclassHeaderProps> = () => {
    const [open, setOpen] = useState(false);
    const [runners, setRunners] = useState([]);
    const [initialLoad, setInitialLoad] = useState(false);
    const router = useRouter();
    const { t, lang } = useTranslation("translation");
    const user = useAuthUser();

    useEffect(() => {
        const fetchData = async () => {
            const response = await getUsersWithPhoto();
            if (response.status === 200) {
                setRunners(response.data);
            }
        };
        fetchData();
    }, []);

    const loadVideo = () => {
        try {
            if (!initialLoad) {
                setInitialLoad(true);
                const video: any = document.getElementById("promo_video");
                // Trigger loading by changing the preload attribute
                // Load the video
                video.load();

                // Play the video
                video.play();
            }
        } catch {
            console.log("Something went wrong, contact post@runagain.com if it continues");
        }
    };

    const handleJoinCommunityButton = () => {
        if (user.id) {
            router.push(FIND_FRIENDS);
        } else {
            setOpen(true);
        }
    };

    return (
        <>
            <Box>
                <Container
                    maxWidth="xl"
                    sx={{
                        px: { lg: "50px" },
                        py: "60px",
                        height: "auto",
                    }}
                >
                    <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            lg={12}
                            sx={{ display: "flex", justifyContent: "center" }}
                        >
                            <Typography variant="h3" component="h1" mb={1} sx={classes.title}>
                                {t("frontPage.joinComunity", null, { default: "" })}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                mt: 2,
                                mb: 2,
                                display: "flex",
                                justifyContent: "flex-end",
                                overflow: "hidden",
                            }}
                        >
                            {runners.map((runner: IRunnerProps) => {
                                return <RunnerAvatar {...runner} key={runner.username} />;
                            })}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{
                                mt: 2,
                                mb: 2,
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    sx={classes.button}
                                    onClick={handleJoinCommunityButton}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: { xs: "24px", md: "32px" },
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("frontPage.joinNow", null, { default: "" })}
                                    </Typography>
                                </Button>
                            </>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mt: 2,
                                mb: 2,
                                height: { xs: "180px", md: "400px", lg: "600px" },
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >

                            <video
                                id="promo_video"
                                onClick={()=> loadVideo()}
                                style={{
                                    cursor: "pointer",
                                    height: "100%",
                                    borderRadius: "20px",
                                }}
                                controls
                                preload="none"
                                poster={thumbnail.src}
                                src="https://firebasestorage.googleapis.com/v0/b/nestelop-production.appspot.com/o/Images%2Fvideo%2FRunAgain.com%20-%20connecting%20the%20world%20of%20runners%20-%20find%2C%20book%20and%20discuss%20-%20Concept%20promo%20(short).mp4?alt=media&token=cbbd1722-01f6-49b6-a2fb-a05550a56787"
                            ></video>
                        </Grid>
                    </Grid>
                </Container>
                <Modal open={open} onClose={() => setOpen(false)} sx={{ overflow: "auto" }}>
                    <Box component="div">
                        <RegisterComponent onClose={() => setOpen(false)} category={FRIEND} />
                    </Box>
                </Modal>
            </Box>
        </>
    );
};

export interface IRunnerProps {
    photoURL: string;
    username: string;
    firstName: string;
}

const RunnerAvatar: FC<IRunnerProps> = ({ username, photoURL, firstName }) => {
    const router = useRouter();
    const goToProfile = () => {
        router.push(PROFILE + "/" + username);
    };
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
            onClick={goToProfile}
        >
            <Image
                src={photoURL}
                alt={firstName}
                width="60"
                height="60"
                quality={40}
                style={{
                    borderRadius: "60px",
                    border: "4px solid",
                    borderColor: "white",
                    marginRight: -10,
                }}
            />
        </Box>
    );
};

export default JoinCommunity;

export { RunnerAvatar };

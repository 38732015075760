import firebaseTriggerUrls from "../../constants/firebaseTriggers";
import axios from "axios";

async function GetUserInformation(friendUsername: string, user: any): Promise<any> {
    const URL = firebaseTriggerUrls.GetUserInformation;
    const token = await user.getIdtokenWithretry();

    const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
    };

    const data: any = {
        friendUsername: friendUsername,
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "post",
        timeout: 10000,
        data: data,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

async function getUsersWithPhoto(): Promise<any> {
  const URL =
  process.env.NEXT_PUBLIC_DEVELOPMENT === "development"
      ? "https://cloudrun-x2lc2wibla-ew.a.run.app/get_users_with_photo"
      : "https://cloudrun-pgjjiy2k6a-ew.a.run.app/get_users_with_photo";

    const headers = {
        "Content-Type": "application/json",
    };

    const promise = axios({
        headers: headers,
        url: URL,
        method: "get",
        timeout: 5000,
    });

    const response = promise.then((response: any) => response).catch((e) => e);
    return response;
}

export { GetUserInformation, getUsersWithPhoto };
